import React from "react";
import { Typography } from "antd";

const ProjectDetail = () => {
  return (
    <Typography.Title level={3} className="my-6">
      Project detail page goes here
    </Typography.Title>
  );
};

export default ProjectDetail;
