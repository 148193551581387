export const actionType = {
  // me
  SET_ME: "SET_ME",

  // projects
  SET_PROJECT_LIST: "SET_PROJECT_LIST",
  SET_PROJECT_CATEGORIES: "SET_PROJECT_CATEGORIES",
  SET_PROJECT_ERROR: "SET_PROJECT_ERROR",
  SET_PROJECT_MEMBERS: "SET_PROJECT_MEMBERS",
  SET_PROJECT_DETAIL: "SET_PROJECT_DETAIL",

  // tasks
  SET_TASK_TYPES: "SET_TASK_TYPES",
  SET_TASK_DETAIL: "SET_TASK_DETAIL",
  SET_TASK_ERROR: "SET_TASK_ERROR",
  GET_PRIORITY: "GET_PRIORITY",
  GET_STATUS: "GET_STATUS",
  GET_PROJECT_MEMBERS: "GET_PROJECT_MEMBERS",

  // drawer modal
  HIDE_DRAWER: "HIDE_DRAWER",
  OPEN_FORM_IN_DRAWER_POPUP: "OPEN_FORM_IN_DRAWER_POPUP",
  SET_SUBMIT_FUNCTION: "SET_SUBMIT_FUNCTION",

  // users
  SET_USER_LIST: "SET_USER_LIST",

  // comments
  SET_COMMENT_ERROR: "SET_COMMENT_ERROR",
};
